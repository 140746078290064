export const serviceIdMap = new Map();

export const ServiceIds = {
    daily: "vc-fanclash-quizinc-cm-f-daily-01",
    weekly: "vc-fanclash-quizinc-cm-f-weekly-01",
    monthly: "vc-fanclash-quizinc-cm-f-monthly-01"
}

serviceIdMap.set(ServiceIds.free, 1);
serviceIdMap.set(ServiceIds.daily, 2);
serviceIdMap.set(ServiceIds.weekly, 3);
serviceIdMap.set(ServiceIds.monthly, 4);
